/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import sber from '@/store/sber'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import ButtonMenu from '@/assets/js/Button/Menu/ButtonMenu'
import ButtonToggle from '@/assets/js/Button/Toggle/ButtonToggle'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import ButtonProduct from '@/assets/js/Button/Product/ButtonProduct'
import Tab from '@/assets/js/Tab/Tab'
import TabService from '@/assets/js/Tab/TabService'
import TabPrice from '@/assets/js/Tab/TabPrice'
import TabProduct from '@/assets/js/Tab/TabProduct'
import TabProducts from '@/assets/js/Tab/TabProducts'
import TabTable from '@/assets/js/Tab/TabTable'
import TabGallery from '@/assets/js/Tab/TabGallery'
import Faq from '@/assets/js/Faq/Faq'
import TextShow from '@/assets/js/Text/Show/TextShow'
import MapIframe from '@/assets/js/Map/Iframe/MapIframe'
import AppForm from '@/components/Form/AppForm.vue'
import AppFormReview from '@/components/Form/Review/AppFormReview.vue'
import AppCalc from '@/components/Calc/AppCalc.vue'
import AppSber from '@/components/Sber/AppSber.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    new SwiperSlider()
    new ButtonMenu()
    new ButtonToggle()
    new ButtonTop()
    new ButtonProduct()
    new Tab()
    new TabService()
    new TabPrice()
    new TabProduct()
    new TabProducts()
    new TabTable()
    new TabGallery()
    new Faq()
    new TextShow()
    new MapIframe()

    if(document.getElementById('block--form-price') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-price')
    }

    if(document.getElementById('block--form-up') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-up')
    }

    if(document.getElementById('block--form-up2') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-up2')
    }

    if(document.getElementById('block--form-size') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-size')
    }

    if(document.getElementById('block--form-calc') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-calc')
    }

    if(document.getElementById('block--form-job') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-job')
    }

    if(document.getElementById('block--form-product') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-product')
    }

    if(document.getElementById('block--form-review') != null) {
        createApp({})
            .component('block-review', AppFormReview)
            .use(Maska)
            .use(form)
            .mount('#block--form-review')
    }

    if(document.getElementById('block--calc') != null) {
        createApp({})
            .component('block-calc', AppCalc)
            .use(Maska)
            .use(form)
            .mount('#block--calc')
    }

    if(document.getElementById('block--sber') != null) {
        createApp({})
            .component('block-sber', AppSber)
            .use(Maska)
            .use(sber)
            .mount('#block--sber')
    }
})
